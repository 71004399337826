import { ParkAvailability } from 'apollo/types';

export const title = 'Preguntas frecuentes';
export const tosLink =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/policies/TOS_Tricao_2023.pdf';
export const content = (
  parkAvailability: ParkAvailability[]
): { question: string; answer: string; question_en: string; answer_en: string }[] => {
  const parkAvailabilityWinter = parkAvailability.find(
    (availability) => availability.isWinterTime === true
  );
  const parkAvailabilitySummer = parkAvailability.find(
    (availability) => availability.isWinterTime === false
  );

  if (parkAvailabilityWinter === undefined || parkAvailabilitySummer === undefined) {
    return [];
  }

  const winterAvailability: { aperture: string; closure: string } = {
    aperture: `${parkAvailabilityWinter.aperture.split(':')[0]}:${
      parkAvailabilityWinter.aperture.split(':')[1]
    } `,
    closure: `${parkAvailabilityWinter.closure.split(':')[0]}:${
      parkAvailabilityWinter.closure.split(':')[1]
    } `,
  };

  const summerAvailability: { aperture: string; closure: string } = {
    aperture: `${parkAvailabilitySummer.aperture.split(':')[0]}:${
      parkAvailabilitySummer.aperture.split(':')[1]
    } `,
    closure: `${parkAvailabilitySummer.closure.split(':')[0]}:${
      parkAvailabilitySummer.closure.split(':')[1]
    } `,
  };

  const faq = [
    {
      question: 'Cómo llegar',
      answer:
        'Parque Tricao está ubicado en Avenida Las Brisas, 4,25 km, Santo Domingo, Región de Valparaíso. Puedes llegar en auto particular, contamos con estacionamientos sin costo adicional y según disponibilidad. No hay transporte público que llegue directo hasta el parque. Desde Llolleo o Santo Domingo, puedes coordinar con un colectivo, taxi o usar alguna aplicación de transporte y solicitar que te dejen al interior del parque, en Plaza La Virgen. Recuerda dejar coordinado el traslado de regreso. <a href="https://maps.app.goo.gl/gwESEtErtkVnqibz6">Mapa indicaciones en Google Maps</a>',
      question_en: 'Which are the aviary schedules?',
      answer_en:
        '9:30-10:30. 11:00-12:00. 12:30-13:30. 14:00-15:00. 15:30-16:30. 17:00-18:00.',
    },
    {
      question: 'Dónde comprar las entradas',
      answer:
        'La venta de tickets se realiza únicamente a través de nuestra página web. No hay venta presencial de entradas. El parque se reserva el derecho de administrar el número de visitas de acuerdo a los flujos máximos diarios. <a href="https://tricao.cl/date">Ir a comprar</a>',
      question_en: 'Can I get there by public transportation?',
      answer_en:
        'The only way to access Parque Tricao is through private transportation. There is no public transportation that arrives directly to the park, so you should plan your visit ahead of time.',
    },
    {
      question: 'Horarios de atención',
      answer: `<ul><li>Marzo a diciembre: ${winterAvailability.aperture} a ${winterAvailability.closure} horas</li><li>Enero y febrero: ${summerAvailability.aperture} a ${summerAvailability.closure} horas.</li><ul>`,
      question_en: 'What is included with admission?',
      answer_en:
        'A general admission ticket allows access to a number of important areas within the park, among them are: a network of scenic lookouts, the Plaza la Virgen, the Humedal Giverny, rides on the raft, kayaking, the beach, trails for hiking and biking, the pump track and playground. All other activities such as the aviary, the nursery garden and use of the barbecue picnic areas have an additional fee.',
    },
    {
      question: 'Días de apertura',
      answer:
        'El parque está abierto durante todo el año, aunque los días de apertura pueden variar según la temporada. Te recomendamos consultar nuestro calendario de reservas para obtener información detallada sobre los días de apertura: <a href="https://tricao.cl/date">www.tricao.cl/date</a>',
      question_en: 'What is included with admission?',
      answer_en:
        'A general admission ticket allows access to a number of important areas within the park, among them are: a network of scenic lookouts, the Plaza la Virgen, the Humedal Giverny, rides on the raft, kayaking, the beach, trails for hiking and biking, the pump track and playground. All other activities such as the aviary, the nursery garden and use of the barbecue picnic areas have an additional fee.',
    },
    {
      question: 'Horario de ingreso al Aviario',
      answer:
        'El horario que seleccionaste al comprar tus entradas, corresponde al rango de horario en que debes ingresar al Aviario. No corresponde a un tiempo de permanencia en el Aviario ni de llegada al parque. Puedes llegar al parque desde las 9:00 horas para recorrer los otros sectores, independiente del horario de ingreso al Aviario seleccionado.',
      question_en: 'Which days are ideal for visiting the park?',
      answer_en:
        'Parque Tricao is open to the public from Tuesday to Sunday, holidays and public holidays. Since there is a maximum daily capacity of visitors, Parque Tricao reserves the right to administer the number of visits according to the maximum daily allowance.',
    },
    {
      question: 'Cambio de fecha/horario',
      answer:
        'Puedes cambiar la fecha de tu visita u horario de ingreso al Aviario directamente a través de nuestra página web con, al menos, 24 horas de anticipación a la fecha indicada en tu ticket. Ingresa al siguiente enlace: <a href="https://tricao.cl/purchaseChange">https://tricao.cl/purchaseChange</a> y sigue los pasos. No se realizan devoluciones.',
      question_en: 'Is it also open on holidays?',
      answer_en: 'Yes.',
    },
    {
      question: 'Tiempo de recorrido',
      answer:
        'Puede variar según las actividades que realices, pero recomendamos dedicar al menos entre 4 a 6 horas. Esta estimación no incluye necesariamente las pausas para descansar o disfrutar de una comida. Dado que el parque abarca más de 100 hectáreas, te recomendamos llegar a las 9:00 AM y aprovecharlo como una experiencia de día completo. Descarga el mapa del parque y planifica tu recorrido. <a href="https://tricao-file-storage-dev.s3.amazonaws.com/maps/Map_Tricao_2023.pdf" target="_blank" rel="noreferrer">LINK MAPA</a>',
      question_en: 'Can I buy my ticket at the entrance?',
      answer_en:
        'Since there is a maximum daily visitor capacity, we invite you to buy your ticket in advance on this site. Although it can be acquired at the entrance, there is a risk that there are no places left to enter the park and the aviary. The park reserves the right to manage the number of visits according to the maximum daily flows. By COVID protocol, only tickets will be sold through the website.',
    },
    {
      question: 'Cupos limitados',
      answer:
        'El parque cuenta con una capacidad máxima de visitas diarias. En caso de no haber entradas disponibles en la página web, no se hacen sobrecupos ni ventas de entradas de manera presencial. El parque se reserva el derecho de administrar el número de visitas de acuerdo a los flujos máximos diarios.',
      question_en: 'What is the park schedule?',
      answer_en: `Summer (November to March): admission of visitors from ???? a.m. until 6:00 p.m., park closes at ?? p.m. Winter (April to October): admission of visitors from ?? a.m. to 5:00 p.m., park closes at ?? p.m.`,
    },
    {
      question: 'Días de lluvia',
      answer:
        'Parque Tricao permanece abierto en días de lluvia y no se reembolsan las entradas si llueve durante la visita. Considera que puedes cambiar la fecha de tu visita con, al menos, 24 horas de anticipación a la fecha indicada en tu ticket. Puedes hacer el cambio de fecha aquí: <a href="https://tricao.cl/purchaseChange">https://tricao.cl/purchaseChange</a>. No se aceptan cambios de fecha por lluvia fuera de este plazo. Planifica tu visita según tus preferencias y revisa nuestros Términos y Condiciones antes de comprar tus entradas, ya que no se realizan devoluciones.',
      question_en: 'What food can I take into the park?',
      answer_en:
        'Bring what you need to stay hydrated and energized to perform the various activities within the Park, for example a snack or sandwich. It’s important to remember that full picnics or lunches are restricted to the areas of the Quinchos (Barbecue picnic areas), which must be reserved in advance.',
    },
    {
      question: 'Estacionamientos y baños',
      answer:
        'Contamos con estacionamientos y baños en cada uno de los sectores del parque. No tienen costo adicional. Los baños cuentan con acceso a agua potable.',
      question_en: 'What does the rental of quincho include?',
      answer_en:
        'All the quinchos have a gas grill (it will be delivered at the entrance), chairs, garbage bag (which you will then have to take with you), and the necessary equipment so you can have lunch and spend the day in Parque Tricao accompanied by your friends and family. Remember to be reserved in advance. You must bring: food and the utensils you need.',
    },
    {
      question: 'Accesibilidad silla de ruedas',
      answer:
        'Debido a la geografía propia del lugar, no contamos con acceso universal. Sin embargo, parte de los senderos de Aviario, Humedal Giverny y Jardín Botánico están habilitados para personas que desplacen en silla de ruedas. Además, contamos con estacionamientos y baños preferenciales en todos nuestros sectores, así como señaléticas de rutas sugeridas en algunos puntos del parque. También ofrecemos kayaks adaptados para personas con movilidad reducida.  En cuanto al uso de Quinchos, te recomendamos puntualmente Quincho La Roca o Quincho el Zorro, los cuales cuentan con estacionamientos y baños cercanos y accesibles. Adicionalmente, tenemos una handbike eléctrica disponible para préstamo, sin costo adicional. El uso de la handbike está destinado únicamente para el sector del Aviario y su uso está contemplado por 1 hora. Escríbenos a contacto@tricao.cl para coordinar la reserva de la handbike.<br></br>*El valor de las entradas varía según el rango etario del visitante, por el momento no contamos con precios diferenciados para personas con movilidad reducida.',
      question_en: 'Is there a restaurant?',
      answer_en:
        "The park has a cafeteria that offers snacks and sandwiches for those who visit us. Additionally, during the summer of 2019 we will have gastronomic activities on weekends that will be announced ahead of time. At the moment it's closed due to the pandemic.",
    },
    {
      question: 'Accesibilidad coche de bebés',
      answer:
        'Regularmente recibimos familias que vienen con coche. Ten cuenta que no todos los senderos son accesibles para coches debido a la topografía del parque, que incluye escaleras y algunas zonas de difícil acceso. Sin embargo, con ayuda, podrás recorrer gran parte de ellos. Además, nuestros baños en Humedal Giverny, Aviario, Jardín Botánico, Plaza La Virgen y Restaurante cuentan con mudadores.',
      question_en: 'Why can’t I take my pet?',
      answer_en:
        'Parque Tricao is an ecological reserve where we protect various species of native fauna, within this framework the presence of pets is a threat and can affect the balance of the ecosystem.',
    },
    {
      question: 'Zonas de pícnic',
      answer:
        'Las zonas de pícnic están habilitadas con mesas y sillas y su uso está incluido en la entrada general. Te sugerimos traer alimentos fríos tipo snack y agua para mantenerte hidratado y con energía. Encuentra zonas de pícnic en Plaza La Virgen, Humedal Giverny, Playa Blanca, Playa Escondida, Plaza Tricahue y miradores. <ul><li>No hay microondas ni horno, así que te recomendamos traer alimentos fríos como frutas, sándwiches, frutos secos, etc.</li><li>• No se permite el ingreso de cocinillas o parrillas, sin embargo, puedes reservar un Quincho a través de la página, los cuales cuentan con parrilla.</li><li>No contamos con basureros, toda la basura que generes, deberás llevarla de vuelta contigo.</li></ul>¡Mantengamos limpios nuestros espacios naturales!',
      question_en: 'How old do you have to be to enter alone?',
      answer_en:
        'After the age of 15, you can enter independently. Children under 14 must be accompanied by an adult of responsible age.',
    },
    {
      question: 'Quinchos',
      answer:
        'Los quinchos son espacios privados, equipados con parrilla a gas (incluyendo el gas), además de mesas y sillas para una capacidad máxima de 25 personas. Ideal para hacer un almuerzo elaborado junto a tu familia y amigos, rodeados de naturaleza. Puedes revisar cada uno de ellos aquí: <a href="https://tricao.cl/place/12">https://tricao.cl/place/12</a>.  La reserva de alguno de nuestros quinchos se debe hacer directamente a través de nuestro sitio web. Primero, selecciona alguna de las entradas disponibles y una vez dentro de la entrada podrás agregar un quincho a tu compra. Ten en cuenta que cada integrante debe contar con su entrada personal de ingreso al parque. Trae tu comida y todos los utensilios necesarios para preparar tu almuerzo. Los quinchos no cuentan con acceso a electricidad.',
      question_en: 'Who pays differentiated rate?',
      answer_en:
        'All children under 4 enter free. Children between 4 and 14 years and adults over 65 pay a differentiated rate. Parque Tricao could request the identification document if necessary.',
    },
    {
      question: 'Kiosco',
      answer:
        'Contamos con un kiosco en Plaza La Virgen que ofrece algunos alimentos en venta, como empanadas, sándwiches, galletas entre otros snacks. La disponibilidad de productos puede variar. También puedes traer tu propio snack.',
      question_en: 'Is there cell phone signal in Parque Tricao?',
      answer_en:
        'Due to the distinct geography of Parque Tricao, cell phones can get signal, but with limited access in some areas. In any case, there are central areas where you will find complete coverage. The signal also varies depending on the cell phone service provider.',
    },
    {
      question: 'Restaurante',
      answer:
        'Días de atención: <br> Enero y febrero: Lunes a domingo de 12:00 a 18:00 horas. <br> Marzo a diciembre: Sábados, domingos, festivos y vacaciones de 12:00 a 17:00 horas. <ul><li>Se ofrecen platos caseros del día, sándwiches, jugos naturales de temporada y variedad de postres.</li><li>La atención es por orden de llegada, según disponibilidad, no se aceptan reservas.</li><li>Cada integrante debe contar con su entrada de ingreso al parque para acceder al restaurante.</li><li><a href="https://tricao-file-storage-prod.s3.amazonaws.com/cafe-menu.pdf" target="_blank" rel="noreferrer">Presiona aquí para ver la carta</a></li></ul>',
      question_en: 'Is there a place to recharge my phone?',
      answer_en: 'No, not at the moment, so bring your cell phone well charged.',
    },
    {
      question: 'Circuito Mountain Bike',
      answer:
        'Circuito de mountainbike cross-country de 25 km. de largo, que cruza quebradas, campos y costa. Para acceder al circuito de MTB tienes dos opciones: <ul><li>Entrada Circuito Mountain bike: Permite el ingreso únicamente al circuito de bicicletas, excluye ingreso al parque general.</li><li>Entrada General: Incluye uso del circuito de MTB, además de acceso al parque general (puedes incluir ingreso al Aviario y Jardín Botánico según tu preferencia).</li></ul>*El circuito está sujeto a cierre por lluvia y/o mantención.<br>Descarga el mapa del circuito MTB y planifica tu ruta.<br><a href="https://tricao-file-storage-dev.s3.amazonaws.com/maps/Map_Tricao_2023.pdf" target="_blank" rel="noreferrer">LINK MAPA</a>',
      question_en: 'Is there Wifi in Parque Tricao?',
      answer_en:
        'For now we do not have Wifi available to the public. Once the service is available, we will publish that information.',
    },
    {
      question: 'Canopy',
      answer:
        'Para disfrutar de esta actividad, debes realizar la reserva y pago directamente en Plaza La Virgen dentro del parque, el mismo día de tu visita. El valor es de $20.000 por persona, con descuentos especiales para grupos de 4 y 6 personas. Es una actividad apta para todas las edades a partir de los 5 años, siempre que se cumplan los requisitos de altura y peso (consultar en el lugar). El peso máximo permitido es de 90 kilos. Por motivos de seguridad, esta actividad no está permitida para embarazadas. No necesitas experiencia previa, ya que nuestros guías especializados te darán todas las indicaciones necesarias. El canopy está disponible todos los días en enero y febrero, y únicamente fines de semana, festivos y vacaciones de invierno de marzo a diciembre.',
      question_en: 'Canopy',
      answer_en:
        'To enjoy this activity, you must make the reservation and payment directly at Plaza La Virgen within the park, the same day of your visit. The value is $20,000 per person, with special discounts for groups of 4 and 6 people. It is an activity suitable for all ages from 5 years old, provided that the height and weight requirements are met (consult at the location). The maximum weight allowed is 90 kilos. For safety reasons, this activity is not allowed for pregnant women. You do not need previous experience, as our specialized guides will give you all the necessary instructions. The canopy is available every day in January and February, and only on weekends, holidays and winter vacations from March to December.',
    },
    {
      question: 'Balsa',
      answer:
        'La balsa es un medio de transporte que conecta el Muelle Tricahue con el Humedal Giverny y su uso está incluido en la entrada general, sin costo adicional. Este servicio tiene cupos limitados y se asigna por orden de llegada. Durante tu visita, asegúrate de consultar con el guía a cargo la disponibilidad de cupos y los horarios de zarpe en el muelle de salida. Ten en cuenta que el traslado no garantiza un viaje de ida y vuelta, ya que los cupos de retorno también se asignan por orden de llegada. En caso de no encontrar cupo para el regreso, deberás considerar volver a pie por el sendero de trekking (aproximadamente 1.7 km). La balsa es accesible para personas en silla de ruedas y coches de bebé. Su operación se mantiene durante todo el año, pero está sujeta a modificaciones o interrupciones debido a fallas técnicas, condiciones climáticas adversas u otros factores que puedan afectar su funcionamiento.',
      question_en: 'Raft',
      answer_en:
        'The raft is a means of transport that connects the Tricahue dock with the Giverny wetland and its use is included in the general entry, without additional cost. This service has limited capacity and is assigned by order of arrival. During your visit, make sure to consult with the guide in charge of the availability of seats and the departure times at the departure dock. Keep in mind that the transfer does not guarantee a round trip, since the return seats are also assigned by order of arrival. In case you do not find a seat for the return, you should consider walking back by the trekking trail (approximately 1.7 km). The raft is accessible for people in wheelchairs and baby cars. Its operation is maintained throughout the year, but is subject to modifications or interruptions due to technical failures, adverse weather conditions or other factors that may affect its operation.',
    },
    {
      question: 'Kayaks',
      answer:
        'El uso de kayaks, stand up paddle y bote a remo están incluidos en la entrada general, no tienen valor adicional. Su uso está sujeto a disponibilidad diaria y no cuenta con un horario fijo, ya que puede variar según la temporada (alta o baja) y el día de la semana. Los paseos se asignan por orden de llegada, por lo que te recomendamos acercarte a uno de nuestros guías el día de tu visita para conocer los detalles y programar tu paseo de hasta 30 minutos en el tranque. Los kayaks pueden ser utilizados de manera individual desde los 15 años, mientras que la edad mínima para participar es de 5 años, siempre que los menores vayan acompañados por un adulto responsable. Es obligatorio utilizar chaleco salvavidas, participar en la charla de seguridad y seguir las indicaciones del encargado del servicio para garantizar una experiencia segura y agradable.',
      question_en: 'Kayaks',
      answer_en:
        'The use of kayaks, stand up paddle and rowing boat is included in the general entry, it does not have an additional value. Its use is subject to daily availability and does not have a fixed opening time, as it can vary depending on the season (high or low) and the day of the week. The tours are assigned by order of arrival, so we recommend that you come to one of our guides the day of your visit to know the details and schedule your walk of up to 30 minutes in the lake. Kayaks can be used individually from 15 years old, while the minimum age to participate is 5 years old, always provided that the minors are accompanied by an adult responsible. It is mandatory to use a life jacket, participate in the safety talk and follow the instructions of the service manager to ensure a safe and pleasant experience.',
    },
    {
      question: 'Bazar',
      answer:
        'El bazar ofrece productos exclusivos del parque, como libretas, jockeys y relojes, además de pinturas, libros, plantas entre otros. Aquí también puedes comprar plantas del vivero. Solo se aceptan pagos con tarjeta. Su horario puede variar según la temporada y permanece cerrado durante el horario de colación. Te recomendamos consultar presencialmente el horario de funcionamiento del bazar el día de tu visita para más detalles.',
      question_en: 'Bazar',
      answer_en:
        'The bazar offers exclusive products from the park, such as notebooks, jerseys and watches, as well as paintings, books, plants, etc. You can also buy plants from the nursery here. Only payments with card are accepted. Its schedule may vary depending on the season and remains closed during the lunchtime. We recommend consulting the opening hours of the bazar on the day of your visit for more details.',
    },
    {
      question: 'Prohibido ingreso de mascotas',
      answer:
        'Está prohibido el ingreso de mascotas, de cualquier tipo y tamaño, por el cuidado de la flora y fauna que habita en el parque.',
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'No hay zonas aptas para el baño',
      answer: 'No hay zonas habilitadas para el baño en el tranque del parque.',
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'Zonas de WiFi',
      answer:
        'Contamos con algunos puntos con accesibilidad gratuita a conexión de WiFi. Por la particular geografía de Parque Tricao, existe señal de celular pero con acceso limitado en algunos puntos. De todas maneras, hay sectores centrales donde encontrarás cobertura total. También varía la señal dependiendo de tu compañía telefónica. Recuerda traer tu teléfono cargado, ya que no contamos con puntos de carga.',
      question_en: 'What do I do with my trash?',
      answer_en:
        'We are committed to caring for the environment and preserving this natural reserve. To help us in this regard, we ask that all the trash you bring into the park you take with you when you leave, so together we will maintain the park in the best conditions. Remember to bring your trash bag. “Pack it in, pack it out.”',
    },
    {
      question: 'Edad ingreso independiente',
      answer:
        'A partir de los 15 años se permite el acceso independiente. Menores de 14 años deben ingresar acompañados de un mayor de edad responsable. Parque Tricao podría solicitar el documento de identificación en caso de ser necesario.',
      question_en: 'How do I get a map of the park?',
      answer_en:
        'Individual maps are usually available at the entrance to the park. Throughout the park there are several signs with maps, or you can view a map of the park online.',
    },
    {
      question: 'Residuos',
      answer:
        'Te pedimos que toda la basura que produzcas al interior del parque, te la lleves cuando te retires, y así juntos mantengamos en las mejores condiciones el parque. Acuérdate de traer tu bolsa. No contamos con basureros dentro del parque.',
      question_en: 'Terms and conditions',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'Tarifa diferenciada',
      answer:
        'Todos los niños menores de 5 años entran gratis. Niños entre los 5 y 14 años y adultos sobre 65 años pagan una tarifa diferenciada. Parque Tricao podría solicitar el documento de identificación en caso de ser necesario.',
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'No ofrecemos alojamiento',
      answer:
        'Dentro del parque no contamos con servicios de hospedaje ni zonas de camping, el parque sólo recibe visitas dentro del horario establecido. Sugerimos buscar alternativas de alojamiento en Santo Domingo o alrededores.',
      question_en: 'No offer lodging',
      answer_en:
        'We do not offer lodging or camping services within the park. The park only receives visits within the established hours. We recommend looking for alternative accommodation options in Santo Domingo or nearby.',
    },
    {
      question: 'Descargar mapas',
      answer:
        '<a href="https://tricao-file-storage-dev.s3.amazonaws.com/maps/Map_Tricao_2023.pdf" target="_blank" rel="noreferrer">Mapa Parque</a><br><a href="https://tricao-file-storage-dev.s3.amazonaws.com/maps/Map_MTB_2023.pdf" target="_blank" rel="noreferrer">Mapa Rutas Mountain Bike</a>',
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'Descargar infografía aves Aviario',
      answer:
        '<a href="https://tricao-file-storage-dev.s3.amazonaws.com/aviaryBirds/inforgraph.pdf" target="_blank" rel="noreferrer">Infografía aves Aviario</a>',
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
    {
      question: 'Términos y condiciones',
      answer: `<a href="${tosLink}" target="_blank" rel="noreferrer">Términos y condiciones</a>`,
      question_en: 'Risks of the visit',
      answer_en: 'You can review them at the following link: ',
    },
  ];

  return faq;
};
