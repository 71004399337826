export const title = 'Horarios y precios';
export const adults = 'Adultos (15-64)';
export const childrenEnterForFree = '*Niños menores de 5 años entran gratis.';
export const others = 'Niños (5-14)/Adulto mayor(65+)';
export const closedTemporary = 'CERRADO TEMPORALMENTE';
export const pay = 'Ir a comprar';
export const entrances = 'Entradas';
export const includeToYourEntrance = 'Incluye a tu entrada (opcional)';
export const summerMonths = 'Enero y Febrero:';
export const winterMonths = 'Marzo a Diciembre:';
export const importantInformation = 'Información importante';
export const onlyOnlineSales =
  '- <b>¡Entradas limitadas!</b> Disponibles sólo por venta online, no hay venta presencial.';
export const childrenFree = '- <b>Niños menores de 5 años entran gratis.</b>';
export const value = 'Valor';
export const grills = 'Quinchos';
export const whatIncludes = '¿Qué incluye?';
export const showLess = 'Mostrar menos';
