export const title = 'BIENVENIDO A TRICAO!';
export const subtitle = 'Actividades';
export const activitiesSuccessMessage = 'Actividades cargadas corrrectamente';
export const tricaoLife = 'La vida Tricao';
export const followUs =
  'Síguenos @parquetricao y entérate de todas nuestras novedades y actividades.';
export const respectMessage = 'Una invitación para todos con respeto por el entorno';
export const respectMessageSmall =
  'El futuro del parque depende de que respetemos la naturaleza y a los otros visitantes. Organízate y protégelo.';
export const shareImageMessage =
  'Comparte tus fotos con el hashtag #ParqueTricao y menciónanos en tu historia @parquetricao';
export const sloganPartOne = 'La magia de la naturaleza,';
export const sloganPartTwo = 'la cultura';
export const sloganPartThree = 'y la vida al aire libre';
export const youtubeEmbedId = 'pq2If5-JFOc';
export const youtubeTitle = 'Video Tricao Home';

export const buyTickets = 'Comprar tickets';
export const buyGiftCard = 'Comprar Gift Card';
export const conservationTitle = '100 hectáreas para la protección de la biodiversidad';
export const conservationSubTitle =
  'Estamos abocados al rescate y preservación del bosque nativo y de la vegetación autóctona propia de las quebradas de la zona central de Chile.';

export const conservationCarousel = [
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
    title: '100 Hectáreas',
    subTitle: 'de recuperación',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_2.jpg',
    title: 'Más de 60 especies',
    subTitle: 'De aves nativas',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_3.jpg',
    title: '12,000 estudiantes al año',
    subTitle: 'En Aula Tricao',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_4.jpg',
    title: 'Más de 350 especies',
    subTitle: 'De plantas y flores',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_5.jpg',
    title: '65% de eficiencia',
    subTitle: 'En el recurso hídrico',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_6.jpg',
    title: 'Más de 40.000',
    subTitle: 'Árboles plantados',
  },
];
export const dropImgUrl =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/images/landing/drop.jpeg';

export const dropMainMessage = 'Bienvenidos a Parque Tricao';
export const dropSecondaryMessage =
  'Abocados al rescate y preservación del bosque nativo y de la vegetación autóctona propia de las quebradas de la zona centrar de Chile.';
export const dropButton = 'Conoce sobre Fundación Parque Tricao';

export const placeCardTag = 'Sector, tag, etc.';

export const placesInfoTitle = 'Los espacios únicos de Parque Tricao';

export const placesInfoText =
  'Buscamos fomentar una educación integral, siendo la naturaleza el eje central para el desarrollo de actividades culturales y recreativas.';

export const ticketSaleTitle = 'Venta de entradas';

export const ticketsSaleDisclaimer = '*No hay ventas presencial de entradas';
export const prices = 'Horarios y precios';
export const otherSpaces = 'Otros espacios únicos de Parque Tricao';
