/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { Card, Badge, Collapse } from 'antd';
import {
  Pack,
  EntranceTicketType,
  EntranceImage,
  ActivityImage,
  PackImage,
} from 'apollo/generated/types';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/schedulesAndPrices';
import placeholderImage from 'assets/images/placeholder-image.png';
import BadgeIcon from 'assets/images/badge-icon.svg';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import styles from './EntranceCard.module.scss';

interface Props {
  item: Pack | EntranceTicketType;
  isPack: boolean;
  images: EntranceImage[] | ActivityImage[] | PackImage[];
}

const SelectCard: FC<Props> = ({ item, isPack, images }: Props) => {
  const { Meta, Grid } = Card;
  const firstImage = images.length ? images[0] : null;

  const [panelHeaderText, setPanelHeaderText] = React.useState<string>(
    texts.whatIncludes
  );

  const isFullPack = () => {
    const fullPackIncludedElements = ['entrada general', 'aviario', 'jardín botánico'];
    return (
      isPack &&
      fullPackIncludedElements.every((name) => item.name?.toLowerCase().includes(name))
    );
  };

  const handlePanelClick = (key: string | string[]) => {
    const isPanelOpen = key.length > 0;
    setPanelHeaderText(isPanelOpen ? texts.showLess : texts.whatIncludes);
  };

  const firstParagraphDescription = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: item.description || '' }}
    />
  );
  const placeholder = (
    <img className={styles.CardImage} src={placeholderImage} alt="placeholder" />
  );
  const title = (
    <>
      <h3>{item.name}</h3>
      <hr className={styles.BodyHorizontalLine} />
    </>
  );

  const renderOverlay = () => {
    const isEntranceTicketTypeDisabled =
      item.__typename === 'EntranceTicketType' && !item.status && item.visibility;
    if (isEntranceTicketTypeDisabled) {
      return (
        <div className={styles.Overlay}>
          <div className={styles.ClosedTemporaryContainer}>
            <h2 className={styles.ClosedTemporaryText}>{texts.closedTemporary}</h2>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.Container}>
      {renderOverlay()}
      <Card
        className={styles.Card}
        bordered={false}
        cover={
          isPack ? (
            <Badge className={styles.CountBadge}>
              {isFullPack() && (
                <img src={BadgeIcon} alt="badge" className={styles.FullIcon} />
              )}
              {firstImage ? (
                <img
                  className={styles.PackCardImage}
                  alt="Pack"
                  src={firstImage?.image || ''}
                />
              ) : (
                placeholder
              )}
            </Badge>
          ) : (
            <Badge className={styles.CountBadge} color="#f26d31">
              {firstImage ? (
                <img
                  className={styles.CardImage}
                  alt="Entrance"
                  src={firstImage?.image || ''}
                />
              ) : (
                placeholder
              )}
            </Badge>
          )
        }
      >
        <Meta className={styles.CardText} title={title} />

        <Grid style={{ width: '60%' }} className={styles.PriceDetail} hoverable={false}>
          <p>{texts.others}</p>
          <h3 className={styles.Price}>{priceFormat.format(item.otherValue || 0)}</h3>
        </Grid>
        <Grid style={{ width: '40%' }} className={styles.PriceDetail} hoverable={false}>
          <p>{texts.adults}</p>
          <h3 className={styles.Price}>{priceFormat.format(item.adultValue || 0)}</h3>
        </Grid>
        <Grid style={{ width: '100%' }} className={styles.PriceDetail} hoverable={false}>
          <p className={styles.ChildrenEnterForFree}>{texts.childrenEnterForFree}</p>
        </Grid>
        <Grid className={styles.DescriptionSpace} hoverable={false}>
          <Collapse ghost onChange={handlePanelClick}>
            <CollapsePanel
              className={styles.ShowMoreCollapse}
              key="1"
              header={panelHeaderText}
            >
              <p className={styles.Description}>{firstParagraphDescription}</p>
            </CollapsePanel>
          </Collapse>
        </Grid>
      </Card>
    </div>
  );
};

export default SelectCard;
